import styled from 'styled-components'
import { colors } from '../../../../theme'
import { Box } from 'grommet'

export const Wrapper = styled.div`
  position: relative;
`

export const CardContent = styled.div`
  > * {
    margin-bottom: 20px;
  }

  > :nth-child(1) {
    display: flex;
    > :first-child {
      margin-right: 20px;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    position: relative;
    width: 100%;
    max-width: none;
    padding-bottom: 20px;
    ::after {
      position: absolute;
      bottom: 0px;
      left: -24px;
      height: 1px;
      background-color: ${colors.gray};
      opacity: 0.5;
      width: calc(100% + 48px);
      content: '';
    }
  }
`

export const Name = styled.div`
  font-size: 20px;
  color: ${colors.indigo};
`

export const Email = styled.div`
  font-size: 15px;
`

export const StyledBox = styled(Box)`
`
