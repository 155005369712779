import { Box } from "grommet"
import styled from 'styled-components'
import { colors } from '../../../../theme'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-columns: auto;
  grid-gap: 20px;
`

export const Actions = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    width: 100%;
    > * {
      width: 100%;
    }
  }
  >:first-child > :first-child {
    display: flex;
    align-items: center;
  }
  >:first-child > :first-child > :last-child {
    margin-top: 15px;
    font-size: 18px;
  }
  border-bottom: 1px solid ${colors.lightGray};
  > :last-child {
    cursor: pointer;
    user-select: none;
  }

  .user-stats {
    margin-top: 15px;
  }
`

export const AddBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const GlobalStats = styled.div`
  margin-bottom: 30px;
`
