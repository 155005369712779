import { useApolloClient } from "@apollo/client"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { colors } from "../../theme"
import Loader from "../UI/Loader"
import SubscriptionStatus from "../UI/SubscriptionStatus"
import Actions from "./components/Actions"
import Content from "./components/Content"
import Navigation from "./components/Navigation"
import { ACTIONS, CustomerPageContext } from "./context"
import GET_CUSTOMER_INFO from "./graphql/getCustomerInfo.graphql"

const UserInformation = styled.div`
  font-size: 23px;
  color: ${colors.indigo};
  font-weight: 500;
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 16px;
  }
  margin-bottom: 20px;
`

const CustomerInfo = ({ email }) => {
  const { state, dispatch } = useContext(CustomerPageContext)
  const { user } = state
  const [loading, setLoading] = useState(true)
  const client = useApolloClient()
  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_CUSTOMER_INFO,
      variables: { email },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    })
    dispatch({ type: ACTIONS.SET_USER, payload: data.findUser })
    setLoading(false)
  }

  if (loading) return <Loader />
  return (
    <div>
      <UserInformation>
        <SubscriptionStatus
          status={user.subscription && user.subscription.status}
        />
        {user.name}
      </UserInformation>
      <Actions />
      <Navigation />
      <Content />
    </div>
  )
}

CustomerInfo.propTypes = {}

CustomerInfo.defaultProps = {}

export default CustomerInfo
