import { useApolloClient } from "@apollo/client"
import { navigate } from "gatsby"
import { useContext, useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { CustomerPageContext } from "./context"
import GENERATE_STRIPE_TOKEN from './graphql/generateStripeToken.graphql'
import UPDATE_USER from './graphql/updateUser.graphql'

const CustomerInfoState = () => {
  const [customer, setCustomer] = useState({})
  const { state: { user } } = useContext(CustomerPageContext)
  const client = useApolloClient()

  useEffect(() => {
    setCustomer(user)
  }, [user])

  const updateUser = async (payload) => {
    if (!customer._id) throw new Error('No user specified: _id')
    await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        user: {
          _id: customer._id,
          ...payload
        }
      }
    })
    setCustomer({ ...customer, ...payload })
  }

  const generateStripeToken = async (planId) => {
    const { data } = await client.mutate({
      mutation: GENERATE_STRIPE_TOKEN,
      variables: {
        userId: customer._id,
        planId
      }
    })
    setCustomer({ ...customer, stripeToken: data.generateStripeUpdateToken })
  }

  const updateEmail = async (email) => {
    await updateUser({ email })
    navigate(`/customer/${email}`)
  }

  const updateSubscriptionEndDate = async (subscriptionEndDate) => {
    await updateUser({ subscriptionEndDate })
  }

  const updateCustomSubscription = async (subscriptionEndDate, status) => {
    await updateUser({ subscriptionEndDate, customSubscription: { status } })
    setCustomer(c => ({
      ...c,
      subscription: {
        ...(c.subscription || {}),
        status
      }
    }))
  }

  const updateName = async (name) => {
    await updateUser({ name })
  }

  return { customer, updateEmail, updateName, generateStripeToken, updateSubscriptionEndDate, updateCustomSubscription }
}

const CustomerInfoStateContainer = createContainer(CustomerInfoState)

export const useCustomerInfoState = CustomerInfoStateContainer.useContainer

export default CustomerInfoStateContainer
