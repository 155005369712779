import React from 'react'
import { FormattedNumber } from 'react-intl'

const nonDecimalCurrencies = [
  'jpy',
  'mga',
  'bif',
  'clp',
  'pyg',
  'djf',
  'rwf',
  'gnf',
  'ugx',
  'vnd',
  'vuv',
  'xaf',
  'kmf',
  'xof',
  'krw',
  'xpf'
]

// If the currency is zero-decimal, 
// then multiply the amount by 100 to show the correct value
// https://stripe.com/docs/currencies#zero-decimal
const convertAmount = (amount, currency) => {
  if (nonDecimalCurrencies.indexOf(currency.toLowerCase()) > -1) {
    return amount * 100
  }
  return amount
}

const Currency = (props) => {
  let {
    currency,
    amount,
    minimumFractionDigits,
    maximumFractionDigits
  } = props

  if (nonDecimalCurrencies.indexOf(currency.toLowerCase()) > -1) {
    minimumFractionDigits = 0
    maximumFractionDigits = 0
  }

  return (<FormattedNumber
    value={convertAmount(amount, currency)}
     // eslint-disable-next-line
    style='currency'
    currency={currency}
    minimumFractionDigits={minimumFractionDigits > -1 ? minimumFractionDigits : 2}
    maximumFractionDigits={maximumFractionDigits > -1 ? maximumFractionDigits : 2}
  />)
}

export default Currency
