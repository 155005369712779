import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme';

export const subscriptionStatusColor = (status) => {
  switch (status) {
    case 'trialing':
      return colors.indigo
    case 'active':
      return colors.green
    case 'past_due':
      return '#F3B910'
    case 'cancelled':
      return colors.red
    default:
      return colors.gray
  }
}

export const MAP_STATUS = {
  'active': 'Active',
  'past_due': 'Not paid',
  'trialing': 'Trialing',
  'cancelled': 'Cancelled'
}

const SubscriptionStatusButton = styled.div`
  background-color: ${props => subscriptionStatusColor(props.status)};
  color: white;
  width: fit-content;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 7px;
  height: 35px;
  line-height: 35px;
  padding: 0px 10px;
`

export default ({ status }) => <SubscriptionStatusButton status={status}>{ MAP_STATUS[status] || 'Unknown' }</SubscriptionStatusButton>
