import styled from "styled-components"
import { colors } from "../../../../theme"
import { Box } from "grommet"
import { subscriptionStatusColor } from "../../../UI/SubscriptionStatusButton"

export const Wrapper = styled.div`
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export const CardContent = styled.div`
  position: relative;
  > * {
    margin-bottom: 20px;
  }

  > :nth-child(1) {
    display: flex;
    > :first-child {
      margin-right: 20px;
    }
    > :last-child {
      position: absolute;
      right: 0;
    }
  }

  > :last-child {
    margin-bottom: 0;
    font-weight: bold;
  }
`

export const Name = styled.div`
  font-size: 20px;
  color: ${colors.indigo};
`

export const Email = styled.div`
  font-size: 15px;
`

export const StyledBox = styled(Box)`
  box-shadow: 0 -4px 0 ${props => subscriptionStatusColor(props.status)};
`

export const DashboardStatus = styled.span`
  font-weight: bold;
  background-color: ${props => props.created ? colors.green : colors.red};
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
`

export const StripeToken = styled.div`
  display: flex;
  flex-direction: column;
`
