import React, { useContext } from 'react'
import styled from 'styled-components'
import { CustomerPageContext } from '../../context';
import AccountInformation from '../AccountInformation';
import Children from '../Children';

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Content = (props) => {
  const { state } = useContext(CustomerPageContext)

  if (!state.user._id) { return null }
  return <Wrapper>
    { state.tab === 'account' && <AccountInformation /> }
    { state.tab === 'children' && <Children /> }
  </Wrapper>
}

Content.propTypes = {

}

Content.defaultProps = {

}

export default Content
