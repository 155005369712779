import { TextInput } from 'grommet'
import React, { useState } from 'react'
import Button from '../../../UI/Button'
import EditableText from '../../../UI/EditableText'
import SubscriptionStatusButton from '../../../UI/SubscriptionStatusButton'
import CustomerInfoState from '../../index.state'
import AccountEvents from '../AccountEvents'
import BillingInformation from '../BillingInformation'
import CustomPlanInformation from '../CustomPlan'
import LoginButton from '../LoginButton'
import SubscriptionEndDate from './components/SubscriptionEndDate'
import { CardContent, DashboardStatus, Email, Name, StripeToken, StyledBox, Wrapper } from './index.styles'

const AccountInformation = () => {
  const { customer: user, updateEmail, updateName, generateStripeToken } = CustomerInfoState.useContainer()
  const [planId, setPlanId] = useState('')

  const dashboardLink = user && `https://dashboard.nightzookeeper.com/create-password?userId=${user._id}&hash=${user.hash}`
  return <Wrapper>
    <StyledBox background='white' pad='medium' elevation='small' round='small' status={user.subscription && user.subscription.status}>
      <CardContent>
        <div>
          <SubscriptionStatusButton status={user.subscription && user.subscription.status} />
          <div>
            <Name>
              <EditableText onSave={(value) => updateName(value)}>
                { user.name }
              </EditableText>
            </Name>
            <Email>
              <EditableText onSave={(value) => updateEmail(value)}>
                { user.email }
              </EditableText>
            </Email>
          </div>
          <div>
            { user.stripeToken
              ? <TextInput value={`https://dashboard.nightzookeeper.com/update-subscription?token=${user.stripeToken}`} />
              : <StripeToken>
                <TextInput placeholder='e.g. plan_xxxxx or price_xxxxx' value={planId} onChange={e => setPlanId(e.target.value)} />
                <Button onClick={() => generateStripeToken(planId)}>Generate Stripe Token</Button>
              </StripeToken>
            }
          </div>
        </div>
        <div><span>Dashboard:</span> <DashboardStatus created={user.hasPassword}>{ user.hasPassword ? 'Created' : 'Not created' }</DashboardStatus></div>
        <SubscriptionEndDate />
        { !user.hasPassword && <div><span>Dashboard link:</span> {dashboardLink}</div> }
        <LoginButton />
      </CardContent>
    </StyledBox>
    { user.customPlan ? <CustomPlanInformation /> : <BillingInformation /> }
    <AccountEvents />
  </Wrapper>
}

export default AccountInformation
