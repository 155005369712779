import React, { useContext } from 'react'
import styled from 'styled-components'
import Button from '../../../UI/Button'
import { CustomerPageContext } from '../../context'

const Wrapper = styled.div`
  width: auto;
`

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

const LoginButton = (props) => {
  const { state: { user } } = useContext(CustomerPageContext)

  const onPressed = () => {
    copyToClipboard(user.sharedSecretKey)
    window.open(`https://dashboard.nightzookeeper.com/logout?email=${user.email}`)
  }
    
  return <Wrapper>
    <Button onClick={onPressed}>Login</Button>
  </Wrapper>
}

LoginButton.propTypes = {

}

LoginButton.defaultProps = {

}

export default LoginButton
