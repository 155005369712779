import { Heading } from "grommet"
import { Button } from 'nzk-react-components'
import React from "react"
import { FormattedDate } from "react-intl"
import styled from "styled-components"
import { colors } from "../../../../theme"
import CustomerInfoState from "../../index.state"
import { CardContent, StyledBox, Wrapper } from "./index.styles"
import UpdateCustomPlan from './UpdateCustomPlan'
import useInlineModal from '../../../../hooks/useInlineModal'

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * > * {
    position: relative;
    margin-bottom: 10px;
    > :first-child {
      color: ${colors.darkBlue};
      font-weight: 500;
      font-size: 16px;
    }
  }
  > :last-child {
    width: 200px;
  }
`

const CustomPlanInformation = () => {
  const { customer: user } = CustomerInfoState.useContainer()
  if (!user) return null
  const { subscription } = user
  const [
    displayUpdateModal,
    dismissUpdateModal,
    UpdateModal
  ] = useInlineModal(<UpdateCustomPlan />)

  if (!subscription) return null
  return (
    <Wrapper>
      {UpdateModal}
      <StyledBox
        background="white"
        pad="medium"
        elevation="small"
        round="small"
        status={user.subscription && user.subscription.status}
      >
        <CardContent>
          <Heading color={colors.indigo} level="4">
            <div>Subscription</div>
            <div style={{ float: 'right', marginLeft: 'auto' }}>
              <Button theme='primary' size='small' onClick={displayUpdateModal}>Update</Button>
            </div>
          </Heading>
          <Content>
            <div>
              <div>
                <div>Plan:</div>
                <div>Custom Plan</div>
              </div>
              <div>
                <div>Status:</div>
                <div>{user.subscription.status}</div>
              </div>
            </div>
            <div>
              <div>
                <div>Expires</div>
                <div>
                  <FormattedDate
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    value={user.subscriptionEndDate}
                  />
                </div>
              </div>
            </div>
          </Content>
        </CardContent>
      </StyledBox>
    </Wrapper>
  )
}

export default CustomPlanInformation
