import React from 'react'
import PropTypes from 'prop-types'
import { reducer, initialState, ACTIONS } from './context'
import { useApolloClient } from '@apollo/client';

let CustomerPageContext = React.createContext()

function CustomerPageContextProvider (props) {
  const client = useApolloClient()
  let [state, dispatch] = React.useReducer(reducer, { ...initialState, ...props.state, client })
  let value = { state, dispatch }

  return (
    <CustomerPageContext.Provider value={value}>{props.children}</CustomerPageContext.Provider>
  )
}

CustomerPageContextProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object
}

let CustomerPageContextConsumer = CustomerPageContext.Consumer

export { ACTIONS, CustomerPageContext, CustomerPageContextProvider, CustomerPageContextConsumer }
