import React, { useContext } from 'react'
import { CustomerPageContext, ACTIONS } from '../context'
import { useApolloClient } from '@apollo/client'
import ARCHIVE_PARENT from '../graphql/archiveParent.graphql'
import Button from '../../UI/Button'
import { colors } from '../../../theme'

const CancelAccountButton = () => {
  const { state: { user }, dispatch } = useContext(CustomerPageContext)
  const client = useApolloClient()

  const archiveParent = async () => {
    const { data } = await client.mutate({
      mutation: ARCHIVE_PARENT,
      variables: {
        parentId: user._id
      }
    })
    if (data.archiveParent._id) {
      dispatch({ type: ACTIONS.SET_USER, payload: {...user, subscription: { ...user.subscription, status: 'canceled' }} })
    }
  }

  return <Button onClick={archiveParent} bgColor={colors.red}>
    Cancel account
  </Button>
}

export default CancelAccountButton
