import styled from 'styled-components'
import { colors } from '../../../../theme'
import { Box } from 'grommet'

export const Wrapper = styled.div`
  position: relative;
`

export const CardContent = styled.div`
  > * {
    margin-bottom: 20px;
  }

  > :nth-child(1) {
    display: flex;
    > :first-child {
      margin-right: 20px;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
`

export const StyledBox = styled(Box)`
`

export const Events = styled.div`
  > * {
    position: relative;
    ::after {
      position: absolute;
      top: 0px;
      left: -24px;
      height: 1px;
      background-color: ${colors.gray};
      opacity: 0.5;
      width: calc(100% + 48px);
      content: '';
    }
  }
  > :last-child {
    padding: 10px 0px 0 0;
    margin-bottom: -10px;
  }
`

export const Event = styled.div`
  display: flex;
  padding: 15px 0px 8px 0;
  > :first-child {
    margin-right: 10px;
  }
  > :nth-child(2) {
    display: flex;
    flex-direction: column;
    > :first-child > span {
      font-weight: 500;
    }
    > :last-child {
      font-size: 12px;
      color: ${colors.gray};
    }
  }
`
