import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Box } from 'grommet';
import { ACTIONS, CustomerPageContext } from '../../context';
import { colors } from '../../../../theme';

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-around;
`

const Item = styled.div`
  font-size: 16px;
  flex: 1;
  height: 100%;
  padding: 10px 0;
  cursor: pointer;
  font-weight: 500;
  user-select: none;
  text-align: center;
  ${props => props.selected && css`
    border-bottom: 3px solid ${colors.indigo};
    color: ${colors.indigo};
  `}
`

const Navigation = (props) => {
  const { state, dispatch } = useContext(CustomerPageContext)

  const selectTab = (tab) => {
    dispatch({ type: ACTIONS.SET_TAB, payload: tab })
  }

  return <Wrapper><Box background='#fff' elevation='medium'>
    <Content>
      <Item selected={state.tab === 'account'} onClick={() => selectTab('account')}>Account</Item>
      <Item selected={state.tab === 'children'} onClick={() => selectTab('children')}>Children</Item>
    </Content>
  </Box></Wrapper>
}

Navigation.propTypes = {

}

Navigation.defaultProps = {

}

export default Navigation
