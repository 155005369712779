import { useApolloClient } from "@apollo/client"
import { Link } from "gatsby"
import { Box } from "grommet"
import { Add } from "grommet-icons"
import React, { useContext, useMemo, useState } from "react"
import { FormattedDate } from "react-intl"
import { colors } from '../../../../theme'
import UPDATE_STUDENT from '../../../StudentInfo/components/Details/updateStudent.graphql'
import Avatar from "../../../UI/Avatar"
import Button from "../../../UI/Button"
import { ACTIONS, CustomerPageContext } from "../../context"
import CREATE_STUDENT from "./graphql/createStudent.graphql"
import { Actions, AddBox, GlobalStats, User, Wrapper } from "./index.styles"

const Children = props => {
  const client = useApolloClient()
  const { state, dispatch } = useContext(CustomerPageContext)
  const [loading, setLoading] = useState(false)
  const { user } = state

  const createStudent = async (countryCode, yearGroup) => {
    const { data } = await client.mutate({
      mutation: CREATE_STUDENT,
      variables: {
        parentEmail: user.email,
        parentId: user._id,
        countryCode: countryCode || "GB",
        yearGroup: yearGroup || 3,
      },
    })
    if (data && data.createTutoringStudent) {
      dispatch({
        type: ACTIONS.SET_USER,
        payload: {
          ...user,
          children: [...user.children, data.createTutoringStudent],
        }
      })
    }
  }

  const total = useMemo(() => {
    return user.children.reduce((acc, student) => {
      return {
        nbAnimalsCreated: acc.nbAnimalsCreated + student.nbAnimalsCreated || 0,
        nbChallengesCompleted: acc.nbChallengesCompleted + (student.curriculumGroup?.stats?.challengesData?.nbChallengesCompleted || 0),
        nbGamesCompleted: acc.nbGamesCompleted + (student.gamesStats?.nbGamesPlayed || 0),
        nbLessonsStarted: acc.nbLessonsStarted + (student.lessonsV2Numbers?.total || 0),
        publishedWordCount: acc.publishedWordCount + student.publishedWordCount || 0,
      }
    }, {
      nbAnimalsCreated: 0,
      nbChallengesCompleted: 0,
      nbGamesCompleted: 0,
      nbLessonsStarted: 0,
      publishedWordCount: 0,
    })
  }, [user])

  const syncSubscriptionEndDate = async () => {
    if (loading) return
    setLoading(true)
    await Promise.all(user.children.map(async (child) => {
      return client.mutate({
        mutation: UPDATE_STUDENT,
        variables: {
          user: {
            _id: child._id,
            subscriptionEndDate: new Date(user.subscriptionEndDate)
          }
        }
      })
    }))
    dispatch({
      type: ACTIONS.SET_USER,
      payload: {
        ...user,
        children: [...user.children].map(c => ({
          ...c,
          subscriptionEndDate: user.subscriptionEndDate
        })),
      }
    })
    setLoading(false)
  }

  const canSyncSubscriptions = useMemo(() => {
    return (user.children || []).filter(({ subscriptionEndDate })=> {
      return subscriptionEndDate !== user.subscriptionEndDate
    }).length > 0
  }, [user])

  return (
    <>
    <GlobalStats>
      <div className='user-stat'>Animals created: {total.nbAnimalsCreated}</div>
      <div className='user-stat'>Challenges completed: {total.nbChallengesCompleted}</div>
      <div className='user-stat'>Games played: {total.nbGamesCompleted}</div>
      <div className='user-stat'>Lessons started: {total.nbLessonsStarted}</div>
      <div className='user-stat'>Words published: {total.publishedWordCount}</div>
    </GlobalStats>
    { canSyncSubscriptions && <Actions>
      <Button onClick={syncSubscriptionEndDate} bgColor={colors.indigo}>{ loading ? 'Loading...' : 'Sync all subscription end dates to parent' }</Button>
    </Actions> }
    <Wrapper>
      <AddBox background="#fff" round="small" elevation="medium" pad="small" onClick={() => createStudent()}>
        <Add size='large' />
      </AddBox>
      {(user.children || []).map(student => (
        <User key={student._id} >
          <Link to={`/student/${student.username}`}>
            <Box background="#fff" round="small" elevation="medium" pad="small">
              <Avatar user={student} size="90px" />
              <div>{student.username}</div>
              <div className='user-stats'>
                <div className='user-stat'>Animals created: {student.nbAnimalsCreated || 0}</div>
                <div className='user-stat'>Challenges completed: {student.curriculumGroup?.stats?.challengesData?.nbChallengesCompleted || 0}</div>
                <div className='user-stat'>Games played: {student.gamesStats?.nbGamesPlayed || 0}</div>
                <div className='user-stat'>Lessons started: {student.lessonsV2Numbers?.total || 0}</div>
                <div className='user-stat'>Words published: {student.publishedWordCount || 0}</div>
              </div>
              <div style={{
                color: new Date(student.subscriptionEndDate).getTime() > Date.now() ? 'green' : 'red'
              }}>Expires <FormattedDate value={student.subscriptionEndDate} /></div>
            </Box>
          </Link>
        </User>
      ))}
    </Wrapper>
    </>
  )
}

Children.propTypes = {}

Children.defaultProps = {}

export default Children
