import React, { useContext } from "react"
import { CustomerPageContext } from "../../context"
import { Wrapper, CardContent, StyledBox, Events, Event } from "./index.styles"
import { FormattedDate } from "react-intl"
import { User, UserNew } from "grommet-icons"
import {
  subscriptionStatusColor,
  MAP_STATUS,
} from "../../../UI/SubscriptionStatusButton"
import { Heading } from "grommet"
import { colors } from "../../../../theme"

const AccountEvents = () => {
  const {
    state: { user },
  } = useContext(CustomerPageContext)
  const events = user.events || []

  return (
    <Wrapper>
      <StyledBox
        background="white"
        pad="medium"
        elevation="small"
        round="small"
        status={user.subscription && user.subscription.status}
      >
        <CardContent>
          <Heading color={colors.indigo} level="4">
            Activity
          </Heading>
          <Events>
            {events.map(event => (
              <Event>
                <User size="17px" color={colors.indigo} />
                <div>
                  <div>
                    Customer status changed from{" "}
                    <span
                      style={{
                        color: subscriptionStatusColor(event.from),
                      }}
                    >
                      {" "}
                      {MAP_STATUS[event.from] || "Unknown"}{" "}
                    </span>
                    to{" "}
                    <span
                      style={{
                        color: subscriptionStatusColor(event.to),
                      }}
                    >
                      {MAP_STATUS[event.to] || "Unknown"}
                    </span>
                    .
                  </div>
                  <div>
                    <FormattedDate
                      year="numeric"
                      month="long"
                      day="2-digit"
                      hour="numeric"
                      minute="numeric"
                      value={event.createdAt}
                    />
                  </div>
                </div>
              </Event>
            ))}
            <Event>
              <UserNew size="17px" color={colors.indigo} />
              <div>
                <div>Account created</div>
                <div>
                  <FormattedDate
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    value={user.createdAt}
                  />
                </div>
              </div>
            </Event>
          </Events>
        </CardContent>
      </StyledBox>
    </Wrapper>
  )
}

export default AccountEvents
