import { Calendar } from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { colors } from '../../../../../theme'
import ModalContext from '../../../../Context/ModalContext'
import Button from '../../../../UI/Button'
import Modal from '../../../../UI/Modal'
import CustomerInfoState from '../../../index.state'


const NoSubscription = styled.div`
  > :first-child {
    
  }
  > :nth-child(2) {
    font-size: 13px;
    font-style: italic;
  }
  > :nth-child(3) {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }
`

const Wrapper = styled.div`
  min-width: 300px;
  min-height: 250px;
  padding: 20px;
  > :first-child {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${colors.indigo};
    margin-bottom: 15px;
    text-align: center;
  }
  > :last-child {
    display: flex;
    justify-content: flex-end;
  }
`

const UpdateDateModal = ({ initialValue, onConfirm, onCancel }) => {
  const [date, setDate] = useState(initialValue)
  
  useEffect(() => {
    setDate(initialValue)
  }, [initialValue])

  const onConfirmPressed = () => {
    if (!canSubmit) return
    onConfirm(date)
  }

  const canSubmit = date && date !== initialValue

  return <Modal onCancel={onCancel}>
    <Wrapper>
      <div>Update subscription expiration date</div>
      <Calendar date={date} onSelect={d => setDate(d)} />
      { initialValue && <div>Old expiration date: <FormattedDate value={initialValue} /></div> }
      { date && <div>New expiration date: <FormattedDate value={date} /></div> }
      { !date && <div>Select a date above.</div> }
      <div>
        <Button bgColor={!canSubmit ? colors.gray : colors.green} onClick={onConfirmPressed}>Confirm</Button>
      </div>
    </Wrapper>
  </Modal>
}

const NoSubscriptionEndDate = ({ openModal }) => {
  return <NoSubscription>
    <div>No subscription end date set at parent level</div>
    <div>Setting one will override their kids once the kid subscription end date gets expired.</div>
    { /* eslint-disable-next-line */ }
    <div onClick={openModal}>Set an expiration date</div>
  </NoSubscription>
}

const SubscriptionEndDateDetails = ({ openModal }) => {
  const { customer: user } = CustomerInfoState.useContainer()

  return <NoSubscription>
    <div>Subscription end date: <FormattedDate value={user.subscriptionEndDate} /></div>
    <div>This date will override their kids once the kid subscription end date gets expired.</div>
    { /* eslint-disable-next-line */ }
    <div onClick={openModal}>Change expiration date</div>
  </NoSubscription>
}

const SubscriptionEndDate = (props) => {
  const { customer: user, updateSubscriptionEndDate } = CustomerInfoState.useContainer()
  const modal = useContext(ModalContext)

  const onDateChange = async (date) => {
    await updateSubscriptionEndDate(date)
    modal.closeAll()
  }

  const openModal = () => {
    modal.open(<UpdateDateModal initialValue={user.subscriptionEndDate} onConfirm={onDateChange} />)
  }


  return <div>
    { !user.subscriptionEndDate && <NoSubscriptionEndDate openModal={openModal} /> }
    { user.subscriptionEndDate && <SubscriptionEndDateDetails openModal={openModal} /> }
  </div>
}

SubscriptionEndDate.propTypes = {

}

SubscriptionEndDate.defaultProps = {

}

export default SubscriptionEndDate
