import React from "react"
import styled from 'styled-components'
import StripeLinkButton from "./StripeLinkButton"
import CancelAccountButton from "./CancelAccountButton"

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`

const Actions = props => {
  return (
    <Wrapper>
      <div>
        <StripeLinkButton />
      </div>
      <div>
        <CancelAccountButton />
      </div>
    </Wrapper>
  )
}

Actions.propTypes = {}

Actions.defaultProps = {}

export default Actions
