import React, { useContext } from 'react'
import { CustomerPageContext } from '../context'
import Button from '../../UI/Button'

const StripeLinkButton = () => {
  const { state: { user } } = useContext(CustomerPageContext)

  if (!(user.subscription && user.subscription.customer)) return null

  return <a href={`https://dashboard.stripe.com/customers/${user.subscription.customer.id}`} target='_blank' rel="noopener noreferrer">
    <Button>
      Stripe
    </Button>
  </a>
}

export default StripeLinkButton
