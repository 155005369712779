import React, { useMemo, useState } from 'react'
import { Button, DatePicker } from 'nzk-react-components'
import Select from 'react-select'
import styled from 'styled-components'
import Modal from '../../../UI/Modal'
import { useCustomerInfoState } from '../../index.state'

interface IProps {
  dismiss: () => void
}

const Wrapper = styled.div`
  background-color: white;
  padding: 20px 40px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  .react-datepicker__portal {
    position: fixed;
    z-index: 3;
  }
`

const Content = styled.div`
  min-width: 200px;
  > .field {
    margin-bottom: 10px;
    > .field--name {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }
  > .actions {
    float: right;
    > * { margin-right: 10px; }
    > :last-child { margin-right: 0; }
  }
`

const options = [
  { value: 'active', label: 'Active' },
  { value: 'trialing', label: 'Trialing' },
  { value: 'cancelled', label: 'Cancelled' },
]

const UpdateCustomPlan = (props: IProps) => {
  const { customer: user, updateCustomSubscription } = useCustomerInfoState()
  const [newExpirationDate, setNewExpirationDate] = useState(new Date(user.subscriptionEndDate))
  const [status, setStatus] = useState(user.subscription.status)

  const selectValue = useMemo(() => {
    return options.filter((o) => o.value === status)[0]
  }, [options, status])

  const submit = async () => {
    await updateCustomSubscription(new Date(newExpirationDate).toString(), status)
    props.dismiss()
  }

  return <Modal onCancel={props.dismiss}>
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Content>
        <div className='field'>
          <div className='field--name'>Subscription End Date</div>
          <DatePicker defaultValue={newExpirationDate} onChange={setNewExpirationDate} autoFocus />
        </div>
        <div className='field'>
          <div className='field--name'>Status</div>
          <Select
            onChange={(l) => setStatus(l.value)}
            value={selectValue}
            placeholder="Status"
            options={options}
          />
        </div>
        <div className='actions'>
          <Button size='x-small' theme='red' onClick={props.dismiss}>Cancel</Button>
          <Button size='x-small' theme='confirm' onClick={submit}>Save</Button>
        </div>
      </Content>
    </Wrapper>
  </Modal>
}

export default UpdateCustomPlan
