import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.4);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = ({ children, onCancel }) => {
  
  const onContentClick = (e) => {
    e.stopPropagation()
  }

  return <Overlay onClick={onCancel}>
    <Content onClick={onContentClick}>
      { children }
    </Content>
  </Overlay>
}

const Content = styled.div`
  background-color: #fff;
  border-radius: 6px;
`

const Actions = styled.div`

`

export const ConfirmModal = (props) => {
  const { children } = props
  return <Modal {...props}>
    { children }
    <Actions>
      <div>Yes</div>
      <div>No</div>
    </Actions>
  </Modal>
}

export default Modal
