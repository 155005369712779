import styled from 'styled-components'
import { colors } from '../../theme';

const subscriptionStatusColor = (status) => {
  switch (status) {
    case 'trialing':
      return colors.indigo
    case 'active':
      return colors.green
    case 'past_due':
      return '#F3B910'
    case 'cancelled':
      return colors.red
    default:
      return colors.gray
  }
}

const SubscriptionStatus = styled.div`
  background-color: ${props => subscriptionStatusColor(props.status)};
  height: 20px;
  width: 20px;
  border-radius: 50%;
`

export default SubscriptionStatus
