import React, { ReactElement, useMemo, useState } from 'react'

const useInlineModal = (
  component: ReactElement<any, string>
): [display: () => void, dismiss: () => void, component: any] => {
  const [visible, setVisible] = useState(false);

  const c = useMemo(() => {
    if (!visible) return null;
    return React.cloneElement(component, { dismiss: () => setVisible(false) })
  }, [visible]);

  return [
    () => setVisible(true),
    () => setVisible(false),
    c
  ];
};

export default useInlineModal
