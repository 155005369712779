export let initialState = {
  tab: 'account',
  user: {}
}

export const ACTIONS = {
  SET_TAB: 'SET_TAB',
  SET_USER: 'SET_USER'
}

export let reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TAB: {
      return { ...state, tab: action.payload }
    }
    case ACTIONS.SET_USER: {
      return { ...state, user: action.payload }
    }
    default: {
      return state
    }
  }
}
