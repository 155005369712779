import { Heading } from "grommet"
import React from "react"
import { FormattedDate } from "react-intl"
import styled from "styled-components"
import { colors } from "../../../../theme"
import Currency from "../../../UI/Currency"
import CustomerInfoState from "../../index.state"
import { CardContent, StyledBox, Wrapper } from "./index.styles"

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * > * {
    position: relative;
    margin-bottom: 10px;
    > :first-child {
      color: ${colors.darkBlue};
      font-weight: 500;
      font-size: 16px;
    }
  }
  > :last-child {
    width: 200px;
  }
`

const BillingInformation = () => {
  const { customer: user } = CustomerInfoState.useContainer()
  if (!user) return null
  const { subscription } = user

  if (!subscription) return null
  const coupon = subscription.discount && subscription.discount.coupon
  return (
    <Wrapper>
      <StyledBox
        background="white"
        pad="medium"
        elevation="small"
        round="small"
        status={user.subscription && user.subscription.status}
      >
        <CardContent>
          <Heading color={colors.indigo} level="4">
            Billing
          </Heading>
          <Content>
            <div>
              <div>
                <div>Plan:</div>
                <div>{subscription.plan ? subscription.plan.nickname : 'Undefined'}</div>
              </div>
              { subscription.plan && <div>
                <div>Price:</div>
                <div>
                  <Currency
                    amount={subscription.plan.amount / 100}
                    currency={subscription.plan.currency}
                  />
                </div>
              </div> }
              {coupon && (
                <div>
                  <div>Discount applied:</div>
                  <div>{coupon.percent_off}</div>
                </div>
              )}
            </div>
            <div>
              <div>
                <div>Last payment</div>
                <div>
                  <FormattedDate
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    value={subscription.current_period_start * 1000}
                  />
                </div>
              </div>
              <div>
                <div>Next payment</div>
                <div>
                  <FormattedDate
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    value={subscription.current_period_end * 1000}
                  />
                </div>
              </div>
            </div>
          </Content>
        </CardContent>
      </StyledBox>
    </Wrapper>
  )
}

export default BillingInformation
