import { PageProps } from 'gatsby';
import React from "react"
import CustomerInfo from "../../components/CustomerInfo";
import { CustomerPageContextProvider } from "../../components/CustomerInfo/context";
import CustomerInfoState from "../../components/CustomerInfo/index.state";

const CustomerPage = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <CustomerPageContextProvider>
    <CustomerInfoState.Provider>
      <CustomerInfo email={props.params.email} />
    </CustomerInfoState.Provider>
  </CustomerPageContextProvider>
}

export default CustomerPage
